import React from 'react';

const Merch = () => (
  <section id="merch">
    <h2>Merch</h2>
    <p>Check back soon for our latest merch!</p>
  </section>
);

export default Merch;
